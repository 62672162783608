import React from 'react';
import { hydrate, render } from 'react-dom';
// import ReactDOM from 'react-dom';
import FontFaceObserver from 'fontfaceobserver';
import './reset.css';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

const openSansObserver600 = new FontFaceObserver('Open Sans', { weight: 600 });
const openSansObserver400 = new FontFaceObserver('Open Sans', { weight: 400 });
const overPassMono600 = new FontFaceObserver('Overpass Mono', { weight: 600 });
const overPassMono700 = new FontFaceObserver('Overpass Mono', { weight: 700 });

// When Open Sans is loaded, add a font-family using Open Sans to the body
Promise.all([
  openSansObserver600.load(),
  openSansObserver400.load(),
  overPassMono600.load(),
  overPassMono700.load()
]).then(() => {
  document.body.classList.add('fontLoaded');
});

// ReactDOM.render(<App />, document.getElementById('root'));
const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
